import {
    addWeeks,
    differenceInWeeks,
    endOfWeek,
    format,
    getISOWeek,
    getISOWeekYear,
    startOfISOWeek,
    startOfWeek,
    subWeeks
} from 'date-fns';

import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { months } from 'helpers/helpers';
import MonthPicker from './month-picker';
import WeekPicker from './week-picker';

interface PickerConfig {
    month?: number;
    pickerType: string;
    push;
    setMonth: React.Dispatch<SetStateAction<number | string>>;
    setWeek: React.Dispatch<SetStateAction<number | string>>;
    setYear: React.Dispatch<SetStateAction<number | string>>;
    week?: number;
    year?: number;
}

interface PickerContext {
    context: PickerConfig;
}

const WeekMonthPicker = ({ context }: PickerContext) => {
    const { month, pickerType, setMonth, setWeek, setYear, week, year } =
        context;

    const [selectedDate, setSelectedDate] = useState<Date | number>(0);
    const [selectedWeek, setSelectedWeek] = useState(week);

    const getDate = useCallback((weekNumber, year) => {
        // Uses the 4th as the starting day of month as January 4th is *always*
        // in ISO 8601 Week 1.
        //
        // https://en.wikipedia.org/wiki/ISO_8601#Week_dates
        //
        return new Date(year, 0, 4 + (weekNumber - 1) * 7);
    }, []);

    useEffect(() => {
        setSelectedDate(getDate(week, year));
    }, [getDate, week, year]);

    const start = startOfWeek(selectedDate, { weekStartsOn: 1 });
    const end = endOfWeek(selectedDate, { weekStartsOn: 1 });

    const formatStart = format(start, 'dd/MMM/yyyy');
    const formatEnd = format(end, 'dd/MMM/yyyy');

    const currentWeekRange = `${formatStart} - ${formatEnd}`;

    const weekScroll = (direction: string) => {
        let newDate = new Date(selectedDate);

        if (direction === 'forward') {
            newDate = addWeeks(newDate, 1);
        } else if (direction === 'back') {
            newDate = subWeeks(newDate, 1);
        }

        // We only allow selecting a 2 week window either side of the current
        // week.
        //
        // TODO: This should maybe be a setting that can be tweaked without
        // making a new release
        //
        const thisWeek = startOfISOWeek(new Date());
        const newWeek = startOfISOWeek(newDate);
        const weekDiff = Math.abs(differenceInWeeks(thisWeek, newWeek));

        if (weekDiff > 2) return false;

        setSelectedDate(newDate);
        setSelectedWeek(getISOWeek(newDate));
        setWeek(getISOWeek(newDate));
        setYear(getISOWeekYear(newDate));
    };

    const currentFormattedDate = useCallback(
        (format) => {
            return `${months[Number(month) - 1]}-${year}` as string;
        },
        [month, year]
    );

    const getPrevious = useCallback(() => {
        const curMonthAsNumber = Number(month);
        const curYearAsNumber = Number(year);
        const incrementYear = curYearAsNumber - 1;
        let incrementMonth = curMonthAsNumber - 1;

        if (incrementMonth < 1) {
            setYear(incrementYear.toString());
            incrementMonth = 12;
        }

        if (incrementMonth < 10) {
            setMonth('0' + incrementMonth.toString());
        } else {
            setMonth(incrementMonth.toString());
        }
    }, [month, setMonth, setYear, year]);

    const getNext = useCallback(() => {
        const curMonthAsNumber = Number(month);
        const curYearAsNumber = Number(year);
        let incrementMonth = curMonthAsNumber + 1;
        const incrementYear = curYearAsNumber + 1;

        if (incrementMonth > 12) {
            setYear(incrementYear.toString());
            incrementMonth = 1;
        }

        if (incrementMonth < 10 && incrementMonth > 0) {
            setMonth('0' + incrementMonth.toString());
        } else {
            setMonth(incrementMonth.toString());
        }
    }, [month, setMonth, setYear, year]);

    return (
        <div className="flex">
            {pickerType === 'month' && (
                <MonthPicker
                    getNext={getNext}
                    getPrevious={getPrevious}
                    currentFormattedDate={currentFormattedDate}
                ></MonthPicker>
            )}

            {pickerType === 'week' && (
                <WeekPicker
                    currentWeekRange={currentWeekRange}
                    selectedWeek={selectedWeek}
                    weekScroll={weekScroll}
                ></WeekPicker>
            )}
        </div>
    );
};

export default WeekMonthPicker;
