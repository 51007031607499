import { useNavigate } from 'react-router-dom';

import useAuth from 'authLib/use-auth';

interface IAccountOption {
    action: string;
    id: string;
    label: string;
}

export const accountOptions: IAccountOption[] = [
    { action: '/login', id: '1', label: 'Logout' }
];

const RenderAccountOptions = (option: IAccountOption) => {
    const { logOut } = useAuth();
    const navigate = useNavigate();

    const onHandleClick = (option) => {
        if (option.label === 'Logout') {
            logOut();
        } else {
            navigate(option.action);
        }
    };

    return (
        <div className="w-full" onClick={() => onHandleClick(option)}>
            {option.label}
        </div>
    );
};

export { RenderAccountOptions };
